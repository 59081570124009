import api from '@/services/api';

const url = 'collaborator-category';

const findAll = () => api.get(`${url}/read-collaborator-category`);

const createCategory = (category) => api.post(`${url}/create-collaborator-category`, category);

const updateCategory = (category) => api.put(`${url}/update-collaborator-category`, category);

export default {
  findAll,
  createCategory,
  updateCategory,
};
