<template>
  <v-card>
    <v-toolbar
      dark
      color="primary"
      dense
      class="mb-5"
    >
      <v-btn
        icon
        dark
        @click="close"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        Adicionar nova categoria de colaboradores
      </v-toolbar-title>
      <v-spacer />
    </v-toolbar>

    <v-form ref="form">
      <v-row
        justify="start"
        no-gutters
        class="pa-2 mt-0"
      >
        <v-col
          class="pr-5"
          lg="12"
          md="12"
          sm="12"
        >
          <p>Nome da categoria</p>
          <v-text-field
            v-model="collaboratorCategory.title"
            outlined
            dense
            label="Categoria de colaboradores"
            :rules="$rules.required"
            required
            :items="collaboratorCategory"
            item-text="title"
            item-value="id"
          />
        </v-col>
      </v-row>
    </v-form>

    <v-row
      class="mt-10"
      justify="center"
      no-gutters
    >
      <v-card-actions>
        <v-btn
          color="secondary"
          @click="close"
        >
          Cancelar
        </v-btn>
        <v-btn
          v-if="loading === true"
          color="primary"
          loading
          @click="submit"
        />
        <v-btn
          v-else
          color="primary"
          @click="submit"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-row>
  </v-card>
</template>

<script>
import CollaboratorCategoryService from '../../../services/collaborator-category.service';

export default {
  name: 'CollaboratorCategoryForm',
  props: {
    populateWith: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      collaboratorCategory: {},
      loading: false,
    };
  },
  created() {
    if (this.populateWith.id) {
      this.collaboratorCategory = this.populateWith;
    }
  },
  methods: {
    close() {
      this.$emit('close-dialog');
    },

    async submit() {
      if (this.$refs.form.validate()) {
        if (this.populateWith.id) {
          try {
            this.loading = true;
            await CollaboratorCategoryService.updateCategory(
              this.collaboratorCategory,
            );
            this.$emit('collaborator-edited', { ...this.collaboratorCategory });
            this.$toast.success('Categoria editada com sucesso');
            this.loading = false;
            this.close();
          } catch (e) {
            this.$handleHttpError(e);
            this.loading = false;
          }
        } else {
          try {
            this.loading = true;
            await CollaboratorCategoryService.createCategory(
              this.collaboratorCategory,
            );
            this.$emit('collaborator-added', { ...this.collaboratorCategory });
            this.$toast.success('Categoria cadastrada com sucesso');
            this.loading = false;
            this.close();
          } catch (e) {
            this.$handleHttpError(e);
            this.loading = false;
          }
        }
      } else {
        this.$toast.error('Verifique os campos e tente novamente');
        this.loading = false;
      }
    },
  },
};
</script>
