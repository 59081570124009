<template>
  <div class="pa-6 pt-10">
    <v-data-table
      :headers="headers"
      :items="collaborator"
      :search="search"
    >
      <template #top>
        <v-toolbar flat>
          <v-toolbar-title>Categoria de colaboradores</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          />

          <v-spacer />

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Procurar"
            single-line
            hide-details
          />

          <v-spacer />
          <v-btn
            color="primary"
            dark
            class="mb-2"
            @click="dialog = true"
          >
            Adicionar
          </v-btn>
        </v-toolbar>
      </template>
      <template #item.actions="{ item }">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              v-bind="attrs"
              @click="editItem(item)"
              v-on="on"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Editar</span>
        </v-tooltip>
      </template>
      <template #item.active="{ item }">
        <v-checkbox
          :input-value="item.active"
          class="primary--text"

          disabled
        />
      </template>
      <template #item.createdAt="{ item }">
        <p>{{ item.createdAt | parseDate }}</p>
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialog"
      hide-overlay
      fullscreen
    >
      <form-collaborator
        v-if="dialog"
        :populate-with="collaboratorToEdit"
        @collaborator-added="addCollaborator"
        @collaborator-edited="editCollaborator"
        @close-dialog="closeDialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import CollaboratorCategoryService from '@/services/collaborator-category.service';
import FormCollaborator from './Form';

export default {
  name: 'CollaboratorCategory',
  components: { FormCollaborator },
  data: () => ({
    headers: [
      {
        text: 'ID',
        sortable: true,
        value: 'id',
      },
      {
        text: 'Título',
        sortable: false,
        value: 'title',
      },
      { text: 'Data de Criação', value: 'createdAt', sortable: false },
      {
        text: 'Ações', value: 'actions', sortable: false, align: 'center',
      },
    ],
    collaborator: [],
    search: '',
    dialog: false,
    collaboratorToEdit: {},
  }),

  async created() {
    await this.findAllCollaboratorCategories();
  },

  methods: {
    closeDialog() {
      this.dialog = false;
      this.collaboratorToEdit = {};
    },
    editItem(item) {
      this.collaboratorToEdit = item;
      this.dialog = true;
    },

    async findAllCollaboratorCategories() {
      this.collaborator = await CollaboratorCategoryService.findAll();
    },

    findCollaboratorIndex(id) {
      return this.collaborator.findIndex(
        (listCollaborator) => listCollaborator.id === id,
      );
    },

    async addCollaborator() {
      await this.findAllCollaboratorCategories();
    },

    async editCollaborator() {
      await this.findAllCollaboratorCategories();
    },
  },
};
</script>
